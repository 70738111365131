function calculPMTMIL2(t,n,a){
    return $pv=n,$i=t/100,$n=a,$iN=Math.pow(1+$i,1/12)-1,$pmt_Mil=-$pv*Math.pow(1+$iN,$n)*$iN/(1-Math.pow(1+$iN,$n)),$pmt_Mil
}
function is_numeric(t){
    return new RegExp("^[0-9-.]*$","g").test(t)
}
function arrondi(t,n){
    return Math.round(t*Math.pow(10,n))/Math.pow(10,n)
}
function number_format(t,n,a,e){
    t=(t+"").replace(/[^0-9+\-Ee.]/g,"");
    var r=isFinite(+t)?+t:0,u=isFinite(+n)?Math.abs(n):0,i=void 0===e?"'":e,o=void 0===a?".":a,c="";
    return(c=(u?function(t,n){
        var a=Math.pow(10,n);
        return""+Math.round(t*a)/a
    }
    (r,u):""+Math.round(r)).split("."))[0].length>3&&(c[0]=c[0].replace(/\B(?=(?:\d{3})+(?!\d))/g,i)),(c[1]||"").length<u&&(c[1]=c[1]||"",c[1]+=new Array(u-c[1].length+1).join("0")),c.join(o)
}

$(document).ready(function(t){
    function n(){
        var n=$("#nav .menu li ul.container.credit .block.has-bg .content input"),a=parseInt(n.val().replace(/['"]/g,""));
        if(a>1){
            parseFloat(1.151*a/100);
            mentionalite=.05*Math.round(calculPMTMIL2(4,a,120)/.05),t("#nav .menu li ul.container.credit .block.has-bg .content .mensualiteA").text(mentionalite.toFixed(2))
        }
    }

    $("#nav .menu li ul.container.credit .block.has-bg .content input[type=tel]").focus(function(){
        $(this).val("")
    });

    $("#nav .menu li ul.container.credit .block.has-bg .content input").keyup(function(){
        var a=t(this),e=parseInt(a.val().replace(/['"]/g,""));
        is_numeric(e)?(a.val(number_format(e)),n()):a.val("")
    });

    $(document).on("click",".btn_calculateur",function(t){
        var a=$("#calculateur .montant_credit");
        montant=parseInt(a.val().replace(/['"]/g,"")),isNaN(montant)?(a.val("20'000"),montant=2e4):montant>3e5&&(a.val("300'000"),montant=3e5),function(t){
            $("#nav .menu li ul.container.credit .block.has-bg .content input").val(number_format(t))
        }
        (montant),n()
    });
});
